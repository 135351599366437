<template>
  <div>
    <s-crud
      title="Configuración de Precios"
      add
      remove
      no-full
      :config="config"
      :filter="filter"
      @save="save($event)"
      @addEvent="addEvent($event)"
      ref="crudprices"
      height="500"
      search
      searchInput
    >
      
      <template v-slot:options>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              black 
              @click="openDialogGroupCertification()"
              small
            >
              <i class="fas fa-layer-group"></i>
            </v-btn>
          </template>
          <span>Grupo Certificaciones</span>
        </v-tooltip>

        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              black 
              @click="openDialogGroupConfig()"
              small
            >
              <i class="fas fa-dollar-sign"></i>
              <i class="fas fa-igloo"></i>
            </v-btn>
          </template>
          <span>Grupo Congelado</span>
        </v-tooltip>
      </template>
      

      <template v-slot:filter>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" lg="3">
              <s-select-definition
                v-model="filter.TypePrice"
                :def="1201"
                label="Tipo Precio:"
              ></s-select-definition>
            </v-col>
            <v-col cols="12" lg="3">
              <s-select-definition
                clearable
                full
                label="Zona"
                :def="1253"
                v-model="filter.ZneID"
              ></s-select-definition>
              <!--  <s-selected-zona
            v-model="filter.ZneID"
            full
            label="Zona"
          /> -->
            </v-col>
            <v-col cols="12" lg="3">
              <s-select-definition
                clearable
                v-model="filter.TypeDestiny"
                :def="1342"
                label="Destino:"
              ></s-select-definition>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template scope="props">
        <v-container>
          <v-row>
            <v-col lg="3" class="s-col-form">
              <s-select-definition
                :def="1201"
                label="Tipo Precio"
                @input="getValue($event)"
                :text="props.item.TypePriceText"
                v-model="props.item.TypePrice"
              ></s-select-definition>
            </v-col>
            <v-col lg="3" class="s-col-form">
              <s-select-definition
                :def="1129"
                label="Tipo de moneda"
                v-model="props.item.TypeCurrency"
              ></s-select-definition>
            </v-col>
            <v-col lg="3" class="s-col-form" v-if="cValidated != 3">
              <s-select-definition
                clearable
                label="Zona"
                :def="1253"
                v-model="props.item.ZneID"
                ref="ZneRef"
              ></s-select-definition>
            </v-col>
            <v-col :lg="cValidated != 3 ? 3 : 6" class="s-col-form">
              <s-select-definition
                  label="Destino"
                  :def="1342"
                  v-model="props.item.TypeDestiny"
                ></s-select-definition>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="3" class="s-col-form">
              <s-select-definition
                v-model="props.item.TypeCrop"
                :def="1172"
                label="Tipo de cultivo"
              />
            </v-col>
            <v-col lg="3" class="s-col-form">
              <s-select-definition
                v-model="props.item.TypeCultive"
                :def="1173"
                label="Cultivo"
              />
            </v-col>
            <v-col lg="3" class="s-col-form">
              <s-select-variety
                :text="props.item.HvsCultiveText"
                :cultiveID="props.item.TypeCultive"
                label="Variedad"
                v-model="props.item.VrtID"
              />
            </v-col>
            <v-col lg="3" class="s-col-form">
              <s-text
                decimal
                label="Monto"
                ref="CfpAmount"
                v-model="props.item.CfpAmount"
              ></s-text>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col lg="3" class="s-col-form">
              <s-date
                label="Fecha Inicio"
                v-model="props.item.CfpDateBegin"
              ></s-date>
            </v-col>
            <v-col lg="3" class="s-col-form">
              <s-date
                label="Fecha Fin"
                v-model="props.item.CfpDateEnd"
              ></s-date>
            </v-col>
            <v-col lg="3" class="s-col-form" v-if="cValidated == 1 || cValidated == 3 || cValidated == 2">
              <s-select-payment
                full
                :typeCultive="props.item.TypeCultive"
                label="Tipo Calibre"
                :text="props.item.CategoryCaliberText"
                v-model="props.item.CtgID"
              ></s-select-payment>
            </v-col>
            <v-col lg="3" class="s-col-form" v-if="cValidated == 3">
              <s-select-producer
                label="Productor"
                :text="props.item.PrdCardName"
                v-model="PrdID"
                return-object
                @input="inputProducer($event)"
              ></s-select-producer>
            </v-col>
            <v-col lg="3" class="s-col-form" v-if="cValidated == 2">
              <s-select-type-certification
                label="Tipo Certificado"
                full
                :text="props.item.TypeCertificationText"
                v-model="props.item.CrtID"
              >
              </s-select-type-certification>
            </v-col>
            <v-col lg="3" class="s-col-form" v-if="cValidated == 1 || cValidated == 2 || cValidated == 3">
              <s-text
                decimal
                label="Monto Descarte"
                ref="CfpAmountDiscard"
                v-model="props.item.CfpAmountDiscard"
              ></s-text>
            </v-col>
            <v-col lg="3" class="s-col-form" v-show="cValidated == 3">
              <s-text
                disabled
                label="PrdCardCode"
                v-model="PrdCardCode"
              ></s-text>
            </v-col>
            <!-- <v-col lg="3" class="s-col-form">
              <v-btn color="primary" class="mt-2" block @click="openDialogGroupCertification()">
                Grupo Certificaciones
              </v-btn>
            </v-col> -->
          </v-row>
        </v-container>
      </template>
      <template v-slot:CfpStatusName="{ row }">
        <v-chip
          x-small
          dark
          :color="
            row.CfpStatus == 1 ? 'success' : row.CfpStatus == 2 ? 'error' : ''"
        >
          {{ row.CfpStatusName }}
        </v-chip>
      </template>
      <template v-slot:accion="{ row }">
        <div>
          <v-btn
            v-if="row.CfpStatus != 1"
            x-small
            dark
            color="warning"
            @click="duplicate(row)"
          >
            Duplicar
          </v-btn>
        </div>
      </template>
    </s-crud>

    <v-dialog
      v-model="dialogFrozenGroup"
    >

    </v-dialog>

    <v-dialog v-model="dialogDuplicate" persistent width="700">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="4">
              <s-text
                decimal
                label="Monto"
                ref="CfpAmount"
                v-model="itemDuplicate.CfpAmount"
              ></s-text>
            </v-col>
            <v-col cols="4">
              <s-date
                label="Fecha Inicio"
                v-model="itemDuplicate.CfpDateBegin"
              ></s-date>
            </v-col>
            <v-col cols="4">
              <s-date
                label="Fecha Fin"
                v-model="itemDuplicate.CfpDateEnd"
              ></s-date>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn color="warning" block @click="closeDialogDuplicate()">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" block @click="duplicateSave()">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogGroupCertification"
      persistent
      width="1000"
    >
      <v-card>
        <v-container>
          <v-row>
            <s-toolbar
              label="Grupos de Certificaciones"
              dark
              color="#8E8F91"
              close
              @close="closeDialogGroupCertification()"
              :save="!isEdit"
              @save="groupCertificationSave()"
              :add="isEdit"
              @add="addGroup()"
            ></s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row class="mt-3">
                <v-col class="pb-0 pt-0">
                  <h2>Datos Generales</h2>
                </v-col>
              </v-row>
              <v-col class="pl-0 pr-0"><v-divider></v-divider> </v-col>
              <v-row>
                <v-col cols="6">
                  <s-date
                    label="Fecha Inicio"
                    v-model="itemGroupCertification.CpgDateBegin"
                    :readonly="isEdit"
                  ></s-date>
                </v-col>
                <v-col cols="6">
                  <s-date
                    label="Fecha Fin"
                    v-model="itemGroupCertification.CpgDateEnd"
                    :readonly="isEdit"
                  ></s-date>
                </v-col>
                <v-col cols="6" class="s-col-form">
                  <s-select-definition
                    :def="1129"
                    label="Tipo de moneda"
                    v-model="itemGroupCertification.TypeCurrency"
                  ></s-select-definition>
                </v-col>
                <v-col cols="6" class="s-col-form">
                  <s-text
                    label="Monto"
                    decimal
                    v-model="itemGroupCertification.CpgAmount"
                    :readonly="isEdit"
                  ></s-text>
                </v-col>
                <v-col cols="6" class="s-col-form">
                  <s-text
                    label="Monto Descarte"
                    decimal
                    v-model="itemGroupCertification.CpgAmountDiscard"
                    :readonly="isEdit"
                  ></s-text>
                </v-col>
                <!-- <v-col cols="6" class="s-col-form">
                  <s-select-definition
                    label="Estado"
                    :def="1351"
                    v-model="itemGroupCertification.CpgStatus"
                  ></s-select-definition>
                </v-col> -->
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="mt-3">
                <v-col class="pb-0 pt-0">
                  <h2>Certificaciones</h2>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-3 elevation-5"
                  x-small
                  fab
                  color="warning"
                  @click="addItemCertification()"
                >
                  <i class="fas fa-plus"></i>
                </v-btn>
              </v-row>
              <v-col class="pl-0 pr-0"><v-divider></v-divider> </v-col>
              <v-row justify="center">
                <v-col>
                  <s-select-type-certification
                    label="Tipo Certificado"
                    full
                    return-object
                    v-model="objCertification"
                  >
                  </s-select-type-certification>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :items="itemCertifications"
                    :headers="headersCertifications"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:item.accion="{ item }">
                      <v-btn v-if="!isEdit" block color="error" @click="removeItemCertification(item)" x-small>
                        <v-icon>mdi-close</v-icon></v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col class="pl-0 pr-0"><v-divider></v-divider> </v-col>
          <v-row>
            <v-col>
              <v-data-table
                v-model="selected"
                :items="itemsCertificationGroup"
                :headers="headerGroupCertifications"
                @click:row="rowSelected($event)"
                item-key="CpgID"
                dense
              >
                <template v-slot:item.accion="{ item }">
                  <v-btn block color="error" @click="deleteGroupCertification(item)" x-small>
                    <v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.CpgStatusName="{ item }">
                  <v-chip x-small :color="item.CpgStatusColor">
                    {{item.CpgStatusName}}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="dialogGroupConfig"
      persistent
      width="1000"
    >
      <v-card>
        <v-container v-if="dialogGroupConfig">
          <v-row>
            <s-toolbar
              label="Configuracion Grupal"
              dark
              color="#8E8F91"
              close
              @close="closeDialogGroupConfig()"
              :save="!isEdit"
              @save="groupConfigSave()"
              :add="isEdit"
              @add="addGroup()"
            ></s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-row> 
                <v-col lg="3" class="s-col-form">
                  <s-select-definition
                    :def="1129"
                    label="Tipo de moneda"
                    v-model="TypeCurrencyGc"
                  ></s-select-definition>
                </v-col> 
                <v-col cols="3" class="s-col-form">
                  <s-select-definition
                    v-model="TypePriceGc"
                    :def="1201"
                    label="Tipo Precio:"
                  ></s-select-definition>
                </v-col>
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="Precio"
                    decimal
                    v-model="AmountGc"
                    />
                </v-col>    
                <v-col cols="3" class="s-col-form">
                  <s-text
                    label="Precio Descarte"
                    decimal
                    v-model="AmountDiscardGc"
                    />
                </v-col> 
          </v-row>
          <v-row> 
            <v-col lg="3" class="s-col-form">
              <s-select-definition
                v-model="TypeCultiveGc"
                :def="1173"
                label="Cultivo"
              />
            </v-col>
          
            <v-col cols="3" class="s-col-form">
              <s-text
                label="Monto Bolilla"
                decimal
                v-model="CfpAmountBolillo"
                :readonly="isEdit"
              ></s-text>
            </v-col>
          </v-row>
          <v-row>
             <v-col  cols="10"  >
              <s-select-producer
                label="Productor"               
                v-model="PrdIDConfig"
                return-object
                @input="changeSelectProducer($event)"
              ></s-select-producer>
            </v-col>
             <v-col  cols="2"  >
              <v-btn rounded fab x-small @click="btnAddProdGroupConfig()"
                class="mt-3"
                ><i class="fas fa-plus"></i
              ></v-btn>              
            </v-col>
           <!--:text="props.item.PrdCardName"-->
          </v-row>
          <v-col class="pl-0 pr-0"><v-divider></v-divider> </v-col>
          <v-row>  
            <v-col cols="12">
              <v-data-table
                :items="itemsProdGroupConfig"
                :headers="headersProdGroupConfig"
                :items-per-page="-1"
                hide-default-footer
                dense
              >
                <template v-slot:item.accion="{ item }">
                  <v-btn   block color="error" @click="removeItemProdGroupConfig(item)" x-small>
                    <v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>         
          </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4">
              <v-row>
                <v-col>
                  <s-select 
                    v-model="NumberWeeksYear"
                    :items="NumberWeeksYearRange"
                    label="Semana"
                  />
               
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    
              <v-checkbox
                v-model="days"
                label="Lunes"
                color="red"
                value="1"
                hide-details
              ></v-checkbox>
              
              <v-checkbox
                v-model="days"
                label="Martes"
                color="primary"
                value="2"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="days"
                label="Miercoles"
                color="orange"
                value="3"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="days"
                label="Jueves"
                color="succes"
                value="4"
                hide-details
              ></v-checkbox>
            
                </v-col>
                <v-col>
              <v-checkbox
                v-model="days"
                label="Viernes"
                color="info"
                value="5"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="days"
                label="Sabado"
                color="indigo"
                value="6"
                hide-details
              ></v-checkbox>
               <v-checkbox
                v-model="days"
                label="Domingo"
                color="red"
                value="7"
                hide-details
              ></v-checkbox>
             
                </v-col>
              </v-row>
            </v-col>
          </v-row>
         
        </v-container>
      </v-card>
    </v-dialog>
 
 </div>
</template>
<script>
import sSelectedZona from "@/components/TecnicalAssistence/ProducersFarms/sSelectedZona";
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import SText from "../../../components/Utils/SText.vue";
import SSelectProducer from "@/components/HarvestSchedule/SSelectProducer";
import sProducerService from "@/services/Technicalassistance/ConfigurationPrice";
import sSelectPayment from "@/components/CategoryPayment/SSelectPayment.vue";
import SSelectTypeCertification from "../../../components/Utils/Certification/sSelectTypeCertification.vue";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import _sConfigurationPriceGroupService from "@/services/Technicalassistance/ConfigurationPriceGroupService.js"
/**["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"] */
export default {
  components: {
    SText,
    sSelectedZona,
    SSelectDefinition,
    SSelectProducer,
    sProducerService,
    sSelectPayment,
    SSelectTypeCertification,
    SSelectVariety,
  },
  data: () => ({
    PrdCardCode: '',
    NumberWeeksYearRange:[],
    NumberWeeksYear:0,
    AmountDiscardGc: 0,
    CfpAmountBolillo: 0,
    TypeCultiveGc: 0,
    TypePriceGc: 0,
    TypeCurrencyGc:0 ,
    AmountGc: 0,
    days: [],
    isEdit: false,
    selected: [],
    itemsProdPriceConfigRange: [],
    itemsProdGroupConfig: [],
    itemsCertificationGroup: [],
    headersProdPriceConfigRange: [
      { text: 'ID', value: 'CfpID' },
      { text: 'Tipo de Precio', value: 'TypePrice' },
      
      { text: 'Precio', value: 'CfpAmount' },
      { text: 'Precio de descarte', value: 'CfpAmountDiscard' },
      { text: 'Productor', value: 'PrdCardName' },
      { text: 'Fecha Inicio', value: 'CfpDateBegin' },
      { text: 'Fecha Fin', value: 'CfpDateEnd' },
      { text: 'Tipo de Moneda', value: 'TypeCurrency' },
    ],
    headersProdGroupConfig:[
      {text: 'ID', value: 'PrdID'},
      {text: 'Productor', value: 'PrdCardName'},
      {text: 'Acciones', value: 'accion'}
    ],
    headerGroupCertifications: [
      {text: 'ID', value: 'CpgID'},//CpgAmountDiscard//TypeCurrency
      {text: 'Fecha Inicio', value: 'CpgDateBegin'},
      {text: 'Fecha Fin', value: 'CpgDateEnd'},
      {text: 'Monto', value: 'CpgAmount'},
      {text: 'Monto Descarte', value: 'CpgAmountDiscard'},
      {text: 'Certificaciones', value: 'CertificationsName'},
      {text: 'Estado', value: 'CpgStatusName'},
      {text: 'Acción', value: 'accion'},
    ],
    objCertification: {},
    headersCertifications: [
      {text: 'Certificación', value: 'CrtName'},
      {text: 'Acción', value: 'accion'},
    ],
    itemCertifications: [],
    itemGroupCertification: {},
    dialogGroupCertification: false,
    dialogGroupConfig: false,
    dialogDuplicate: false,
    itemDuplicate: {},
    filter: { CfpTypePrice: 0 },
    cValidated: 0,
    ObjPrecio: {
      CalibreObj: null,
    },
    PrdID: null,
    PrdIDConfig: {},
    //ObjCalibre: {},
    //ObjLocalidad: {},
    ObjCertificacion: {
      CertificacionObj: null,
    },
    config: {
      title: "Config",
      model: {
        CfpID: "ID",
        CfpAmount: "decimal4",
        CfpAmountDiscard: "decimal4",
        CfpAmountBolillo: "decimal4",
        DedDescription: "",
        CfpDateBegin: "date",
        CfpDateEnd: "date",
        CfpStatusName: "",
        accion: "",
      },
      service: sProducerService,
      headers: [
        { text: "Acción", value: "accion" },
        { text: "ID", value: "CfpID" },
        { text: 'Destino', value: 'TypeDestinyName' },
        { text: "Tipo Precio", value: "TypePriceText", width: 150 },
        { text: "Moneda", value: "TypeCurrencyName", width: 150 },
        { text: "Zona", value: "ZneName" },
        { text: "Tipo Cultivo", value: "TypeCropName" },
        { text: "Cultivo", value: "TypeCultiveName" },
        { text: "Variedad", value: "VrtName" },
        { text: "Categoria", value: "CategoryCaliberText" },
        { text: "Importe", value: "CfpAmount" },
        { text: 'Monto Descarte', value: 'CfpAmountDiscard'},
        { text: 'Monto Bolilla', value: 'CfpAmountBolillo'},
        { text: "Fecha Inicio", value: "CfpDateBegin" },
        { text: "Fecha Fin", value: "CfpDateEnd" },
        { text: "Estado", value: "CfpStatusName" },
      ],
    },
    dialogFrozenGroup: false
  }),

  watch: {
    filter(newValue, oldValue) {
      // console.log('filter ', this.filter);
    }
  },

  methods: {
    changeTypePrice(item) {
      this.PrdCardCode = '';
    },
    addEvent(item) {
      this.PrdCardCode = '';
    },
    inputProducer(producer) {
      // console.log("producer:",producer)
      if (producer == null) {
        this.PrdCardCode = '';
        return;
      }else if(this.cValidated == 3){
        this.PrdCardCode = producer.PrdCardCode;
      }
    },
    btnAddProdGroupConfig(){
      // console.log("this.PrdIDConfig", this.PrdIDConfig);
      let p = this.PrdIDConfig;
      if(p == null){
        return;
      }
      if(p.PrdID > 0){       
        var elem = this.itemsProdGroupConfig.find(element => element.PrdID == p.PrdID);
        // console.log(elem);
        if (elem == null) {
          this.itemsProdGroupConfig.push(
            {
              PrdID: p.PrdID,
              PrdCardCode: p.PrdCardCode,
              PrdCardName: p.PrdCardName
            }
          );
        }        
      }
      this.PrdIDConfig = {};
    },
    removeItemProdGroupConfig(item){
 
      let position = this.itemsProdGroupConfig.indexOf(item)
      let elementoEliminado = this.itemsProdGroupConfig.splice(position, 1)

      //console.log("item samir", elementoEliminado);
    },
    groupConfigSave(){

      if (this.itemsProdGroupConfig.length == 0) {
        this.$fun.alert("No se ha agregado ningun Productor", "warning");
        return;
      }
      if (this.days.length == 0) {
        this.$fun.alert("No se ha Seleccionado Dias", "warning");
        return;
      }
      if (this.AmountGc <= 0) {
          this.$fun.alert("El Precio no debe ser 0", "warning");
        return;
      }
      if (this.TypeCultiveGC <= 0) {
          this.$fun.alert("Debe seleccionar un Tipo Cultivo", "warning");
        return;
      }
      //this.TypeCultiveGC
      /*if (this.AmountDiscardGc <= 0) {
          this.$fun.alert("El Precio de descarte no debe ser 0", "warning");
        return;
      }*/

      let dataGroupConfig = {
        itemsProdGroupConfig: this.itemsProdGroupConfig,
        days : this.days,
        Amount: this.AmountGc,
        AmountDiscard: this.AmountDiscardGc,
        TypePrice: this.TypePriceGc,
        TypeCurrency: this.TypeCurrencyGc,
        TypeCultive: this.TypeCultiveGc,
        NumberWeeksYear: this.NumberWeeksYear,
        CfpAmountBolillo: this.CfpAmountBolillo
      }
      // console.log("samir dataGroupConfig", dataGroupConfig);
 
      //samir

      this.$fun.alert("¿Desea Guardar?", "question")
      .then(r => {
        if(r.value){
          _sConfigurationPriceGroupService.groupconfigsave(dataGroupConfig, this.$fun.getUserID())
          .then((r) => {
            // console.log("samir config", r.data);
              this.dialogGroupConfig =  false;
              this.$fun.alert("Datos Guardados correctamente", "success");
          });
        }
      });
    },
    changeSelectProducer(p){
      // console.log("productor", p);
      
    },
    addGroup()
    {
      this.itemCertifications = [];
      this.itemGroupCertification = {};
      this.isEdit = false;
    },

    validateNewGroupCertification()
    {
      let has = false;
      let certificationsNew = [];
      this.itemCertifications.forEach(element => {
        certificationsNew.push(element.CrtName);
      });
      let certificationsNewJoin = certificationsNew.join(', ');
      this.itemsCertificationGroup.forEach(item => {
        if(item.CertificationsName == certificationsNewJoin && item.CpgStatus == 1){
          has = true;
        }
      })
      return has;
    },

    closeDialogGroupCertification()
    {
      this.dialogGroupCertification = false;
      this.itemCertifications = [];
      this.itemGroupCertification = {};
    },
    closeDialogGroupConfig()
    {
      this.dialogGroupConfig = false;
      this.itemCertifications = [];
      this.itemGroupCertification = {};
    },

    rowSelected(row)
    {
      this.itemGroupCertification = row;
      let arr  = [row];
      this.selected = arr;
      _sConfigurationPriceGroupService
      .detaillist(row.CpgID, this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          this.itemCertifications = resp.data;
          if(this.itemCertifications.length > 0)
          {
            this.isEdit = true;
          }
          else{
            this.isEdit = false;
          }
          this.selected = [];
        }
      })
    },

    deleteGroupCertification(item)
    {
      this.$fun.alert("¿Seguro de eliminar?", "question")
      .then(r => {
        if(r.value){
          item.SecStatus = 0;
          item.UsrUpdateID = this.$fun.getUserID();
          _sConfigurationPriceGroupService
          .save(item, this.$fun.getUserID())
          .then(resp => {
            if(resp.status == 200){
              this.$fun.alert("Guardado Correctamente", "success");
              _sConfigurationPriceGroupService
              .list(this.$fun.getUserID())
              .then(resp => {
                if(resp.status == 200){

                  resp.data.forEach(element => {
                      element.CpgDateBegin = this.$moment(element.CpgDateBegin).format(this.$const.FormatDateDB);
                      element.CpgDateEnd = this.$moment(element.CpgDateEnd).format(this.$const.FormatDateDB);
                  });
                  this.itemsCertificationGroup = resp.data;
                  this.itemCertifications = [];
                  this.itemGroupCertification = {};
                }
              })
            }
          })
        }
      })
    },

    openDialogGroupCertification()
    {
      this.dialogGroupCertification = true;
      _sConfigurationPriceGroupService
      .list(this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200){
          // console.log('abrio ', resp.data);
          resp.data.forEach(element => {
                      element.CpgDateBegin = this.$moment(element.CpgDateBegin).format(this.$const.FormatDateDB);
                      element.CpgDateEnd = this.$moment(element.CpgDateEnd).format(this.$const.FormatDateDB);
                  });
          this.itemsCertificationGroup = resp.data;
        }
      })
    },
    openDialogGroupConfig()
        {
          this.dialogGroupConfig = true;
          //samir
           
        },
    removeItemCertification(item)
    {
      this.itemCertifications = this.itemCertifications.filter(element => {
        return element.CrtID != item.CrtID
      })
    },

    addItemCertification()
    {
      
      if(this.objCertification.TcID == undefined){
        this.$fun.alert("Ingrese certificación", "warning");
        return;
      }
      let detail ={
        CpgID: 0,
        CpgID: 0,
        CrtID: this.objCertification.TcID,
        CrtName: this.objCertification.TcDescription,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
        UsrUpdateID: this.$fun.getUserID(),
      }
      this.itemCertifications.push(detail);
    },

    groupCertificationSave()
    {
      if(this.itemGroupCertification.CpgAmount == 0 ||this.itemGroupCertification.CpgAmount == "" || this.itemGroupCertification.CpgAmount == undefined){
        this.$fun.alert("Ingrese monto", "warning");
        return;
      }
      if(this.itemCertifications.length < 2){
        this.$fun.alert("Tiene que ingresar al menos dos certificaciones", "warning");
        return;
      }
      this.$fun.alert("¿Seguro de guardar?", "question")
      .then(r => {
        if(r.value){
          this.itemGroupCertification.CpgID = this.isEdit ? this.itemGroupCertification.CpgID : 0;
          this.itemGroupCertification.CpgStatus = 1;
          this.itemGroupCertification.SecStatus = 1;
          this.itemGroupCertification.UsrCreateID = this.$fun.getUserID();
          this.itemGroupCertification.UsrUpdateID = this.$fun.getUserID();
          this.itemGroupCertification.TasConfigurationPriceGroupDetails = this.itemCertifications;
          if(this.validateNewGroupCertification()){
            this.$fun.alert("Ya existe un grupo de certificaciones registrado", "warning");
          }
          else{
            _sConfigurationPriceGroupService
            .save(this.itemGroupCertification, this.$fun.getUserID())
            .then(resp => {
              if(resp.status == 200){
                this.$fun.alert("Guardado Correctamente", "success");
                this.dialogGroupCertification = false;
                this.itemCertifications = [];
              }
            })
          }
        }
      })
      
    },

    closeDialogDuplicate() {
      this.dialogDuplicate = false;
    },

    duplicate(row) {
      row.CfpID = 0;
      this.itemDuplicate = row;
      this.dialogDuplicate = true;
    },

    duplicateSave() {
      if (
        this.itemDuplicate.CfpAmount == 0 ||
        this.itemDuplicate.CfpAmount == ""
      ) {
        this.$refs.CfpAmount.error("Ingrese Monto");
      }
      if (this.itemDuplicate.CfpAmount <= 0) {
        this.$refs.CfpAmount.error("El monto debe ser mayor a Cero");
      }
      this.$fun.alert("Seguro de guardar?", "question").then((r) => {
        if (r.value) {
          sProducerService
            .save(this.itemDuplicate, this.$fun.getUserID())
            .then((resp) => {
              if (resp.status == 200) {
                this.$fun.alert("Ingresado Correctamente", "success");
                this.closeDialogDuplicate();
                this.$refs.crudprices.refresh();
              }
            });
        }
      });
    },

    Initialize() {
      this.filter = {};
    },
    save(item) {
      if (item.CfpAmount == null) {
        this.$refs.CfpAmount.error("Ingrese Monto");
        return;
      }
      if (item.CfpAmount.trim() == "") {
        this.$refs.CfpAmount.error("Ingrese Monto");
        return;
      }
      if (item.CfpAmount <= 0) {
        this.$refs.CfpAmount.error("El monto debe ser mayor a Cero");
        return;
      }
      if (item.CfpTypePrice == 1) {
        //item.CtgID = item.CtgID;
      } else if (item.CfpTypePrice == 2) {
        //item.CrtID = this.ObjCertificacion;
      } else {
        item.PrdID = this.PrdID.PrdID;
      }
      if(this.cValidated != 3){
        if (item.ZneID == null) {
          //this.$refs.ZneID.error('Ingrese Zona');
          this.$fun.alert("Ingrese Zona", "warning");
          return;
        }
      }
      // console.log(item);
      //item.LocationID = this.ObjLocalidad;
      item.save();
    },
    getValue(val) {
      this.cValidated = val;
      this.PrdID = {};
    },
  },
  created() {
    this.filter = {};
    
      let anioCurrent = new Date().getFullYear();
      // console.log("anioCurrent", anioCurrent);

      this.NumberWeeksYearRange = this.$fun.getNumberWeeksYear(anioCurrent);
      // console.log("getNumberWeeksYear", this.NumberWeeksYearRange);

      this.NumberWeeksYear = this.$fun.getNumberWeekCurrentYear();
  },
  watch: {
    "filter.TypePrice"() {
      if (this.filter.TypePrice == 1){
        this.config.headers.splice(7, 1, {
          text: "Categoria",
          value: "CategoryCaliberText",
        });

        const indexToRemove = this.config.headers.findIndex(header => header.value === "PrdCardCode");
        if (indexToRemove !== -1) {
          this.config.headers.splice(indexToRemove, 1);
        }
      }
      if (this.filter.TypePrice == 2){
        this.config.headers.splice(7, 1, {
          text: "Certificado",
          value: "TypeCertificationText",
        });

        const indexToRemove = this.config.headers.findIndex(header => header.value === "PrdCardCode");
        if (indexToRemove !== -1) {
          this.config.headers.splice(indexToRemove, 1);
        }
      }
      if (this.filter.TypePrice == 3){
        //console.log("Productor", this.filter.TypePrice);
        this.config.headers.splice(7, 1, {
          text: "Productor",
          value: "PrdCardName",
          width: "200"
        });

        const headerExists = this.config.headers.some(header => header.value === "PrdCardCode");

        if (!headerExists) {
          this.config.headers.splice(8, 0, {
            text: "Codigo Productor",
            value: "PrdCardCode",
          });
        }
      }

    },
  },
};
</script>